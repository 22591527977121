import { useQuery } from "react-query"
import { ProjectId } from "@core/types/domain/ProjectType"
import {
  LivestreamType,
  LivestreamTypeSerialized,
  LivestreamUpdate,
} from "@core/types/domain/LiveStream"
import { fetchApi, requireOk, toJson } from "~/services/fetchApi"
import { ensureArray, queryParams, toDate } from "~/utils/jsUtils"
import { queryClient } from "~/utils/fetch/ReactQueryProvider"
import { StreamInstruction } from "@core/types/domain/stream/StreamInstruction"
import { useMemo } from "react"

export function useLivestreams(project: ProjectId[] | ProjectId | null) {
  const projects = ensureArray(project)
  return useQuery(getId(projects), () => fetchLivestreams(projects), {
    enabled: !!projects?.length || projects === null,
  })
}

export function invalidateLivestreams(projects: ProjectId[] | null) {
  queryClient.invalidateQueries(["livestreams"])
}

const getId = (projects: ProjectId[] | null) => ["livestreams", projects]

export function fetchLivestreams(
  projectIds: ProjectId[] | null
): Promise<LivestreamType[]> {
  return fetchApi(
    "/livestream?" + queryParams({ projectIds: projectIds?.join(",") })
  )
    .then(requireOk)
    .then(toJson)
    .then((results: LivestreamTypeSerialized[]) => {
      return results.map(parseResult)
    })
}

export function useLivestream(id: number) {
  return useQuery(["livestream", id], () => fetchLivestream(id), {
    enabled: !!id,
  })
}

export function invalidateLivestream(id: number) {
  queryClient.invalidateQueries(["livestream", id])
}

export function fetchLivestream(id: number): Promise<LivestreamType> {
  return fetchApi(`/livestream/${id}`)
    .then(requireOk)
    .then(toJson)
    .then(parseResult)
}

function parseResult(json: LivestreamTypeSerialized): LivestreamType {
  return {
    ...json,
    plannedStart: toDate(json.plannedStart),
    plannedEnd: toDate(json.plannedEnd),
    start: toDate(json.start),
    end: toDate(json.end),
  }
}

export function useStreamInstructions(streamId: number) {
  return useQuery(
    ["streamInstructions", streamId],
    () =>
      fetchApi(`/livestream/${streamId}/instructions`)
        .then(requireOk)
        .then<StreamInstruction>(toJson),
    { refetchInterval: 1000 * 10 }
  )
}

export function fetchUpdateLivestream(
  id: number,
  update: Partial<LivestreamUpdate>
) {
  return fetchApi(`/livestream/${id}`, {
    method: "POST",
    body: JSON.stringify(update),
  })
    .then(requireOk)
    .then<LivestreamType>(toJson)
}
