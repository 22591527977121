import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { faClock, faStar } from "@fortawesome/free-solid-svg-icons"
import { VideoBrandStats } from "@core/types/detection/DetectionTypes"
import { Brand } from "@core/types/domain/Brand"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDuration } from "~/utils/formatUtils"
import { limit } from "~/utils/jsUtils"
import Widget from "~/components/widget/Widget"
import { sumSizeStats } from "~/screens/app/detection/data/sumBrandStats"

interface TopBrandsWidgetProps {
  stats: VideoBrandStats
  brands: Brand[]
  className?: string
  count?: number
}

export function TopBrandsWidget(props: TopBrandsWidgetProps) {
  const topBrands = useMemo(() => {
    if (!props.stats) return undefined
    const summedByBrand = Object.entries(props.stats).map(([name, stats]) => {
      const summed = sumSizeStats(stats)
      const brand = props.brands.find((b) => b.name === name)
      return {
        ...summed,
        brand: brand || ({ name } as Brand),
      }
    })
    summedByBrand.sort((a, b) => {
      return a.duration < b.duration ? 1 : -1
    })
    return summedByBrand.filter(limit(props.count || 4))
  }, [props.stats])
  return (
    <Widget icon={faStar} title={"Dominant brands"} className={props.className}>
      <Container>
        {topBrands?.map(({ brand, duration, score }) => (
          <BrandItem key={brand.name}>
            <div>
              <img src={brand?.img} alt="" />
            </div>
            <Details>
              <Title>{brand.label || brand.name}</Title>
              <div>
                <FontAwesomeIcon icon={faClock} fixedWidth />{" "}
                {formatDuration(duration, true)}
              </div>
              <div>
                <FontAwesomeIcon icon={faStar} fixedWidth /> {score.toFixed(0)}
              </div>
            </Details>
          </BrandItem>
        ))}
      </Container>
    </Widget>
  )
}

const Details = styled.div`
  font-size: 0.9em;
`

const Title = styled.div`
  font-weight: bold;
  margin-left: 3px;
`

const BrandItem = styled.div`
  display: flex;
  margin: -6px;
  overflow: hidden;
  > div {
    margin: 6px;
  }

  img {
    max-width: 64px;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 12px;
`
