import { useMemo } from "react"
import { Brand } from "@core/types/domain/Brand"
import { VideoBrandStats } from "@core/types/detection/DetectionTypes"
import { useProjectBrands } from "~/services/brands/fetchBrands"
import { firstToUpper } from "~/utils/formatUtils"
import { keyBy } from "~/utils/jsUtils"

export function useBrandsInStats(
  stats: VideoBrandStats
): UseBrandsInStatsResult {
  const { brandByName } = useProjectBrands()
  return useMemo(() => {
    if (!brandByName || !stats)
      return {
        brands: undefined,
        brandByName: {},
      }
    const brands = Object.keys(stats).map((name) => {
      const brand = brandByName[name]
      if (brand) return brand
      console.info("unknown brand: " + name)
      return {
        name,
        label: firstToUpper(name),
        img: `https://s5api.onlinecontrolserver.net/logo/${name}/128`,
      }
    })
    brands.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })
    return {
      brands,
      brandByName: keyBy(brands, (b) => b.name),
    }
  }, [stats, brandByName])
}

interface UseBrandsInStatsResult {
  brands: Brand[]
  brandByName: Record<string, Brand | undefined>
}
