import React, { useMemo } from "react"
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext"
import PdfScreen from "~/layouts/pdf/PdfScreen"
import PdfFooter from "~/layouts/pdf/layout/PdfFooter"
import PdfPage from "~/layouts/pdf/PdfPage"
import PdfProjectHeader from "~/screens/pdf/PdfProjectHeader"
import { PdfTvLogos } from "~/screens/pdf/dashboard/logo/PdfTvLogos"
import { useCurrentProjectTvDetection } from "~/screens/pdf/dashboard/logo/useCurrentProjectTvDetection"

const showDebug = process.env.NODE_ENV === "development"

function PdfLogoPages() {
  const { results } = useCurrentProjectTvDetection()
  const pages = useMemo(() => {
    if (!results) return undefined
    const streamPages = results.map((result, i) => {
      const results = [result]
      const debugInfo = showDebug ? ` (${result.detection.id})` : ""
      return (
        <>
          <PdfTvLogos
            results={results}
            title={`#${i + 1} ${result.stream.title}` + debugInfo}
          />
        </>
      )
    })
    return [
      <>
        <PdfTvLogos
          results={results}
          title={`TV Logos: All ${results.length} streams combined`}
        />
      </>,
      ...streamPages,
    ]
  }, [results])
  return (
    <>
      {pages?.map((page, i) => (
        <Page key={i} number={i + 1} maxPages={pages.length}>
          {page}
        </Page>
      ))}
    </>
  )
}

function PdfLogoDashboard() {
  return (
    <ProjectSelectionContext resolveFromUrl>
      <PdfScreen>
        <PdfLogoPages />
      </PdfScreen>
    </ProjectSelectionContext>
  )
}

function Page(props: { children: any; number: number; maxPages: number }) {
  return (
    <PdfPage
      header={<PdfProjectHeader />}
      footer={<PdfFooter number={props.number} maxPages={props.maxPages} />}
    >
      {props.children}
    </PdfPage>
  )
}

// noinspection JSUnusedGlobalSymbols
export default PdfLogoDashboard
