import { LivestreamDetectionType } from "@core/types/domain/Livestream"
import { sort } from "~/utils/jsUtils"

export function sortStreamResults(results: LivestreamDetectionType[]) {
  return sort(results, (a, b) => {
    if (a.order > b.order) return -1
    else if (a.order < b.order) {
      return 1
    } else if (a.order === b.order) {
      if (a.id > b.id) return -1
      else if (a.id < b.id) return 1
      else return 0
    }
  })
}
