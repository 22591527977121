import { useMemo } from "react"
import {
  LivestreamDetectionType,
  LivestreamType,
} from "@core/types/domain/Livestream"
import { useSelectedProject } from "~/store/project/projectHooks"
import { useLivestreams } from "~/screens/app/detection/livestreams/data/useLivestreams"
import { useStreamDetectionResults } from "~/screens/app/detection/data/fetchDetectionResults"
import { sortStreamResults } from "~/screens/app/detection/stats/stream/sortStreamResults"

export interface StreamWithLatestResult {
  stream: LivestreamType
  detection: LivestreamDetectionType
}

export function useCurrentProjectTvDetection() {
  const project = useSelectedProject()
  const projectIds = useMemo(() => project && [project?.id], [project])

  const { data: streams, isFetching: l1 } = useLivestreams(projectIds)
  const allStreamIds = useMemo(() => streams?.map((s) => s.id), [streams])

  const { data: results, isFetching: l2 } =
    useStreamDetectionResults(allStreamIds)

  const streamWithLatestResult: StreamWithLatestResult[] = useMemo(() => {
    if (!streams || !results) return undefined
    return streams
      ?.map((stream) => {
        const allDetections = results.filter((r) => r.streamId === stream.id)
        sortStreamResults(allDetections)
        return {
          stream,
          detection: allDetections[0],
        }
      })
      .filter((s) => s.detection)
  }, [streams, results])

  return useMemo(() => {
    return {
      results: streamWithLatestResult,
    }
  }, [streamWithLatestResult])
}
